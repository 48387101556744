<script>

</script>

<div class="headline">
    <div class="headline-img">
        <h1><b>chromatic</b>.world</h1>
    </div>
    <h2>Let the games begin.</h2>
    <!-- <Button rounded class="deep-purple accent-1 white-text" size="x-large" style="width: 200px; font-size: 14pt;">PLAY NOW</Button> -->
</div>

<style>    
    /* .headline-img {
        display: flex;
    } */
    /* #world {
        font-size: 62pt;
        transform: translateY(13px);
    }
    img {
        width: 25em;
        height: auto;
    } */
    h1 {
        font-size: 4em;
    }
    b {
        font-size: 1.8em;
        padding: 10px;
        font-family: learning-curve, sans-serif;
        font-weight: 700;
        letter-spacing: 0.5px;
        background: -webkit-linear-gradient(left, #DF5353, #E69256, #E9D069, #98C586, #81B3F3, #A687FC, #E37FA8);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    h2 {
        font-size: 2em;
    }
    .headline {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 50px;
    }
    @media screen and (max-width: 1024px){
		h1 {
            font-size: 2.7em;
            text-align: center;
            margin: -20px;
        }
        b {
            font-size: 1.8em;
            text-align: center;
            padding: 5px;
            font-family: learning-curve, sans-serif;
            font-weight: 700;
            letter-spacing: 0.5px;
            background: -webkit-linear-gradient(left, #DF5353, #E69256, #E9D069, #98C586, #81B3F3, #A687FC, #E37FA8);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
        h2 {
            font-size: 1.7em;
            text-align: center;
            padding: none;
            margin: -10px;
        }
        .headline {
            height: 22vh;
            margin-top: 10vh;
            margin: none;
        }
	}
    /* .button {
        width: 190px;
        padding: 10px 0;
        border-radius: 100em;
        color: white;
        background-color: #B871FF;
        border: none;
        font-size: 1.5em;
        font-weight: bold;
        text-align: center;
    }
    .button {
        display: inline-block;
        border-radius: 10rem;
        color: #fff;
        text-transform: uppercase;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
    }
    .button:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #B871FF;
        border-radius: 10rem;
        z-index: -2;
    }
    .button:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background-color: #FF90D4;
        transition: all 0.3s;
        border-radius: 10rem;
        z-index: -1;
    }
    .button:hover {
        text-decoration: none;
    }
    .button:hover:before {
        width: 100%;
    } */
</style>