<script>
    import { List, ListItem, TextField, Icon, Button, Overlay, MaterialApp, Card } from 'svelte-materialify';

    let rank;

</script>

<List class="elevation-2" style="width: 150px; margin: 20px; background-color: rgba(0, 0, 0, 0.3);">

    <ListItem>
        <span>1</span>
    </ListItem>

    <ListItem>
        <span>2</span>
    </ListItem>

    <ListItem>
        <span>3</span>
    </ListItem>
    
    <ListItem>
        <span>4</span>
    </ListItem>

    <ListItem>
        <span>5</span>
    </ListItem>

    <ListItem>
        <span>6</span>
    </ListItem>

    <ListItem>
        <span>7</span>
    </ListItem>

    <ListItem>
        <span>8</span>
    </ListItem>

    <ListItem>
        <span>9</span>
    </ListItem>

    <ListItem>
        <span>10</span>
    </ListItem>

</List>